/**************************************************
 * Nombre:       Footer
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Button, ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import {Parallax} from 'react-parallax';
import fondo from '../Recursos/equipo_fondo.webp'
import fondoCell from '../Recursos/fonde_equipo_cell.webp'
import logo from '../Recursos/logoblanco.svg'
import logos from '../Recursos/logos.webp'
import {LINKMAP, REDES} from "../Constantes";
import {Location} from "iconsax-react";
import {irUrl} from "../Utilidades/irUrl";
import {theme} from "../Tema";
import {marcarEvento} from "../Servicios/Analiticas/marcarEvento";
import {useDialogo} from "../Modulo_Dialgos/Hooks/useDialog";
import Dialogo_Login from "./Dialogos/Dialogo_Login";


const Footer = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Login})


    const irMap = () => {
        irUrl(LINKMAP)
        marcarEvento('red_google_maps')
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Dialogo/>

            <Grid item lg={12} sm={12} xs={12}>
                <Parallax bgImage={masSM ? fondo : fondoCell} bgImageAlt="equipo"
                          bgImageStyle={{width: '100%'}} title={"equipo"}
                          lazy={true}
                          style={{height: sCell ? 250 : 400, width: '100%'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"

                        sx={{marginTop: sCell ? 16 : 30, pb: 4,}}
                    >

                        <Grid item container lg={9} sm={12} xs={11}
                              sx={{
                                  justifyContent: "center",
                                  backgroundColor: "#00000090",
                                  borderRadius: 4,
                                  backdropFilter: "blur(2px)"
                              }}>
                            <Typography sx={{
                                color: "#fff",
                                fontWeight: 600,
                                fontFamily: "Montserrat",
                                textAlign: "center",
                                fontSize: sCell ? 14 : 20,
                                px: sCell ? 2 : 12,
                                py: sCell ? 2 : 4,
                                filter: "none",
                                lineHeight: 1.1

                            }}>En nuestro
                                compromiso con su carga, asumimos la responsabilidad de superar sus expectativas,
                                ofreciendo
                                un servicio de transporte que hace la diferencia."
                            </Typography>
                        </Grid>


                    </Grid>
                </Parallax>
            </Grid>

            <Grid item lg={12} sm={12} xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={{backgroundColor: "#000"}}
                >

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        sx={{maxWidth: "1400px", px: 3, py: 6}}
                        spacing={4}
                    >

                        {!sCell &&
                        <Grid item lg={2} sm={3} xs={12}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item lg={12} sm={12} xs={12}>
                                    <Typography sx={{color: "#fff", fontWeight: 600, fontFamily: "Montserrat"}}>Links
                                        Rapidos</Typography>
                                </Grid>

                                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                    >

                                        {links.map((item) => {
                                            return (
                                                <Grid item lg={12} sm={12} xs={12}>
                                                    <ButtonBase
                                                        href={"#" + item}
                                                        sx={{py: 0.3, px: 0}}>
                                                        <Typography
                                                            sx={{color: "#fff", fontWeight: 400}}>
                                                            {item}</Typography>
                                                    </ButtonBase>
                                                </Grid>
                                            )
                                        })}

                                        <Grid item lg={12} sm={12} xs={12}>
                                            <ButtonBase
                                                onClick={() => abrir()}
                                                sx={{py: 0.3, px: 0}}>
                                                <Typography
                                                    sx={{color: "#fff", fontWeight: 400}}>
                                                    Administración</Typography>
                                            </ButtonBase>
                                        </Grid>

                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                        }

                        <Grid item lg={4} sm={6} xs={12}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >


                                <Grid item lg={12} sm={12} xs={12}>
                                    <Typography sx={{color: "#fff", fontWeight: 600, fontFamily: "Montserrat"}}>
                                        Contactos
                                    </Typography>
                                </Grid>

                                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                    >

                                        {contactos.map((item) => {
                                            return (
                                                <Grid item lg={12} sm={12} xs={12}>
                                                    <ButtonBase
                                                        sx={{py: 0.3, px: 0}}>
                                                        <Typography
                                                            sx={{color: "#fff", fontWeight: 400}}>
                                                            {item.nombre}</Typography>
                                                    </ButtonBase>
                                                </Grid>
                                            )
                                        })}

                                    </Grid>
                                </Grid>


                            </Grid>
                        </Grid>

                        {sCell &&
                        <Grid item lg={2} sm={4} xs={5}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item lg={12} sm={12} xs={12}>
                                    <Typography sx={{color: "#fff", fontWeight: 600, fontFamily: "Montserrat"}}>Links
                                        Rapidos</Typography>
                                </Grid>

                                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                    >

                                        {links.map((item) => {
                                            return (
                                                <Grid item lg={12} sm={12} xs={12}>
                                                    <ButtonBase
                                                        href={"#" + item}
                                                        sx={{py: 0.3, px: 0}}>
                                                        <Typography
                                                            sx={{color: "#fff", fontWeight: 400}}>
                                                            {item}</Typography>
                                                    </ButtonBase>
                                                </Grid>
                                            )
                                        })}

                                        <Grid item lg={12} sm={12} xs={12}>
                                            <ButtonBase
                                                onClick={() => abrir()}
                                                sx={{py: 0.3, px: 0}}>
                                                <Typography
                                                    sx={{color: "#fff", fontWeight: 400}}>
                                                    Administración</Typography>
                                            </ButtonBase>
                                        </Grid>

                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                        }

                        <Grid item lg={2} sm={3} xs={6}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >


                                <Grid item lg={12} sm={12} xs={12}>
                                    <Typography sx={{color: "#fff", fontWeight: 600, fontFamily: "Montserrat"}}>
                                        Redes Sociales
                                    </Typography>
                                </Grid>

                                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                    >

                                        {REDES.map((item) => {

                                            const irRed = () => {
                                                irUrl(item.url)
                                                marcarEvento('red_' + item.nombre)
                                            }


                                            return (
                                                <Grid item lg={3} sm={3} xs={3}>
                                                    <ButtonBase
                                                        onClick={() => irRed()}
                                                    >
                                                        <item.Icono color={"#fff"} variant={"Bulk"} size={"2rem"}/>
                                                    </ButtonBase>
                                                </Grid>
                                            )
                                        })}

                                    </Grid>
                                </Grid>

                                <Grid container item lg={8} sm={12} xs={12}
                                      sx={{marginTop: 4, justifyContent: "center"}}>
                                    <Button
                                        onClick={() => irMap()}
                                        startIcon={<Location/>} color={"action"}
                                        sx={{color: "#000"}}
                                    >Llevame</Button>
                                </Grid>


                            </Grid>
                        </Grid>


                        <Grid item lg={4} sm={6} xs={12}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: "center", marginTop: 5}}>
                                    <img src={logo} width={"60%"} height={"auto"}
                                         alt={"Logo international logistics MD"}
                                         title={"Logo international logistics MD"}/>
                                </Grid>


                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                                    <img src={logos} width={"60%"} height={"auto"}
                                         alt={"Logos de Certificados"} title={"Logos de Certificados"}/>
                                </Grid>


                            </Grid>
                        </Grid>


                    </Grid>


                </Grid>
            </Grid>


        </Grid>
    )
}

export default Footer;


const links = ["Inicio", "Clientes", "Testimonios", "Contactos", "Equipo", "Nosotros"]

const contactos = [
    {
        nombre: "Ipiales Cra 1a No. 12-09 Barrio victoria norte",
        url: ""
    },
    {
        nombre: "gcomercial@internationalmdsas.com",
        url: ""
    },
    {
        nombre: "gerenciacolmd@gmail.com",
        url: ""
    },
    {
        nombre: "3164738993",
        url: ""
    },
    {
        nombre: "3183516582",
        url: ""
    },
    {
        nombre: "7750450",
        url: ""
    },

]
