/**************************************************
 * Nombre:       Politicas
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import fondo from '../../Recursos/fondo_politicas.png'
import fondo_cell from '../../Recursos/fondo_politicas_cell.png'
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";

const Politicas = () => {
    const {sCell} = useResponsive()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{backgroundColor: '#e0e0e0'}}
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'flex-start', my: sCell ? 6 : 10}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{
                            background: sCell ? `linear-gradient(0deg, #000000 75.5%, rgba(0, 0, 0, 0.2) 99.99%), url(${fondo_cell})` : `linear-gradient(-245deg, #000000 50%, rgba(0, 0, 0, 0.2) 60%), url(${fondo})`,
                            p: sCell ? 4 : 6,
                            pb: 7,
                            boxShadow: 6,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                        }}
                    >


                        <Grid item container lg={7} sm={9} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: sCell ? 12 : 0}}>
                            <Typography sx={{
                                fontWeight: 800,
                                fontSize: sCell ? 24 : 32,
                                color: '#fff',
                                lineHeight: 1.1,
                                fontFamily: 'Montserrat'
                            }}>Politica de
                                Control y
                                Seguridad</Typography>
                        </Grid>

                        <Grid item container lg={7} sm={9} xs={12} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                            <Typography sx={{fontWeight: 400, fontSize: 16, color: '#fff'}}>
                                Como empresa transportadora <span style={{fontWeight: 800}}>International Logistics MD S.A.S</span> ha
                                decidido establecer su
                                compromiso en satisfacer las expectativas de sus asociados de negocio y partes
                                interesadas, al implementar medidas de control y seguridad con el fin de mantener la
                                integridad de sus procesos, promover la prevención de los riesgos propios de sus
                                actividades frente al lavado de activos, contrabando, la proliferación de armas de
                                destrucción masiva, tráfico de estupefacientes, el soborno, la corrupción y demás
                                actividades ilícitas.
                                <br/>
                                <br/>
                                En el manejo de sus operaciones <span style={{fontWeight: 800}}>International Logistics MD S.A.S</span> establece
                                una cadena de
                                actividades controladas a través de la contratación de personal capacitado confiable e
                                idóneo, consecución de clientes reconocidos en el mercado y de intachable trayectoria
                                asi como el cumplimiento de los requisitos legales y reglamentarios con el compromiso
                                que caracteriza a esta empresa y en busca de la mejora continua en cada una de las
                                operaciones, procesos y en general del SGCS se ejerce estricto seguimiento, medición,
                                análisis y trazabilidad al resultado en pro del cumplimiento de los objetivos
                                promoviendo la seguridad en el uso de las tecnologías de la información.

                            </Typography>
                        </Grid>


                    </Grid>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default Politicas