/**************************************************
 * Nombre:       Main
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect, useState} from 'react';
import {Button, ButtonBase, Drawer, Grid, Typography, useMediaQuery} from "@mui/material";
import {CloseSquare, Location} from "iconsax-react";
import {LINKMAP, REDES} from "../../Constantes";
import {irUrl} from "../../Utilidades/irUrl";
import {marcarEvento} from "../../Servicios/Analiticas/marcarEvento";
import logo from "../../Recursos/logoblanco.svg";
import logos from "../../Recursos/logos.webp";
import Barra from "../Barra/Barra";
import Inicio from "../SubSecciones/Inicio";
import Cifras from "../SubSecciones/Cifras";
import Clientes from "../SubSecciones/Clientes";
import Testimonios from "../SubSecciones/Testimonios";
import Contactos from "../SubSecciones/Contactos";
import Equipo from "../SubSecciones/Equipo";
import Servicios from "../SubSecciones/Servicios";
import Nosotros from "../SubSecciones/Nosotros";
import Footer from "../Footer";
import {theme} from "../../Tema";
import {useNavigate} from "react-router-dom";
import Pqrs from "../SubSecciones/Pqrs";
import Politicas from "../SubSecciones/Politicas";


const Main = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const [open, setOpen] = useState(false)
    const [x, setX] = useState("Inicio")
    const navigate = useNavigate()

    const cerrar = () => {
        setOpen(false)
        let hash = window.location.hash
        if (!hash) {
            return
        }
        setTimeout(() => {

            let hash = window.location.hash
            let seccion = hash.replaceAll('#', '')
            marcarEvento('seccion_' + seccion)
            let compo = window.document.getElementById(seccion)
            compo.scrollIntoView({
                behavior: 'smooth', // smooth scroll
                block: 'start' // the upper border of the element will be aligned at the top of the visible part of the window of the scrollable area.
            })
        }, 500)


    }

    useEffect(() => {
        let hash = window.location.hash
        if (!hash) {
            return
        }


        setTimeout(() => {

            let seccion = hash.replaceAll('#', '')
            if (seccion === 'pqrs') {
                alert("Se esta dirigiendo a Pqrs")
                let compo = window.document.getElementById(seccion)
                if (compo) {
                    compo.scrollIntoView({
                        behavior: 'auto', // smooth scroll
                        block: 'start' // the upper border of the element will be aligned at the top of the visible part of the window of the scrollable area.
                    })
                }
            }


        }, 2000)
    }, [])

    return (
        <>


            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{display: 'none'}}
            >

                <h1>Empresa de transporte de carga</h1>
                <p>Operaciones de transporte de carga masiva, nacional e internacional con aliados estratégicos, para
                    prestar un servicio eficiente, seguro y de calidad.</p>

                <h2>Transportadora de carga</h2>

                <p>La tranquilidad de su carga está en nuestras manos. Somos su socio confiable en transporte y
                    logística.</p>
                <li>
                    <ul>
                        Aseguramos que su carga llegue a tiempo y en perfectas condiciones, gracias a nuestro monitoreo
                        en línea 24/7
                    </ul>
                    <ul>
                        Aseguramos que su carga llegue a tiempo y en perfectas condiciones, gracias a nuestro monitoreo
                        en línea 24/7
                    </ul>
                    <ul>En International Logistics MD S.A.S ofrecemos soluciones personalizadas de transporte de carga,
                        adaptadas a las necesidades específicas de cada cliente, garantizando la satisfacción total en
                        el servicio.
                    </ul>
                </li>

                <h2>Empresas de transporte nacional</h2>

                <h2>Agencia de aduanas en colombia</h2>

                <h2>Transporte de mercancia</h2>

                <h2>Seguro de mercancías</h2>

                <h3>rastreo vehiculos de carga</h3>

                <h3>monitoreo vehiculos de carga</h3>

                <h2>Logistica de mercancias</h2>

                <h2>Transporte de carga Ipiales</h2>

                <h2>Transporte de carga Ecuador</h2>


            </Grid>


            <Drawer open={open} onClose={cerrar}
                    anchor={"right"}

            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{width: 250, p: 3}}
                >


                    <Grid item lg={12} sm={12} xs={12}>
                        <ButtonBase onClick={() => cerrar()}>
                            <CloseSquare color={"#fff"} size={"2rem"}/>
                        </ButtonBase>
                    </Grid>


                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            {secciones.map((item, index) => {
                                return (
                                    <Grid
                                        component={"div"}
                                        data-aos="fade-right"
                                        //data-aos-delay={200 * index}
                                        data-aos-duration="1500"
                                        key={index}
                                        item container lg={12} sm={12} xs={12}
                                        sx={{justifyContent: "center", marginTop: 2}}>
                                        <ButtonBase
                                            href={"#" + item}
                                            sx={{
                                                borderColor: "#FF6623", py: 0.5
                                            }}>
                                            <Typography
                                                sx={{
                                                    color: x === item ? "#fff" : "#ffffff80",
                                                    fontFamily: "Montserrat",
                                                    fontSize: 20,
                                                    fontWeight: 700,
                                                    "&:hover": {color: "#fff"},

                                                }}>{item}</Typography>
                                        </ButtonBase>
                                    </Grid>
                                )
                            })}
                        </Grid>


                        <Grid
                            component={"div"}
                            data-aos="fade-right"
                            //data-aos-delay={400}
                            data-aos-duration="1500"
                            item container lg={12} sm={12} xs={12}
                            sx={{justifyContent: "center", marginTop: 2}}>
                            <ButtonBase
                                onClick={() => navigate('/Boletines/nn')}
                                sx={{
                                    borderColor: "#FF6623", py: 0.5
                                }}>
                                <Typography
                                    sx={{
                                        color: '#fff',
                                        fontFamily: "Montserrat",
                                        fontSize: 20,
                                        fontWeight: 700,
                                        "&:hover": {color: "#fff"},

                                    }}>Boletines</Typography>
                            </ButtonBase>
                        </Grid>

                    </Grid>


                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >

                            {REDES.map((item) => {

                                const irRed = () => {
                                    irUrl(item.url)
                                    marcarEvento('red_' + item.nombre)
                                }


                                return (
                                    <Grid item lg={3} sm={3} xs={3}>
                                        <ButtonBase
                                            onClick={() => irRed()}
                                        >
                                            <item.Icono color={"#fff"} variant={"Bulk"} size={"2rem"}/>
                                        </ButtonBase>
                                    </Grid>
                                )
                            })}

                        </Grid>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                        <Button
                            onClick={() => irUrl(LINKMAP)}
                            startIcon={<Location/>} color={"action"}
                            sx={{color: "#000"}}
                        >Llevame</Button>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 20}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                        >

                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: "center", marginTop: 5}}>
                                <img src={logo} width={"90%"} height={"auto"}
                                     alt={"Logo international logistics MD"} title={"Logo international logistics MD"}/>
                            </Grid>


                            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                                <img src={logos} width={"80%"} height={"auto"}
                                     alt={"Logos de Certificados"} title={"Logos de Certificados"}/>
                            </Grid>


                        </Grid>
                    </Grid>

                </Grid>
            </Drawer>


            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >


                <Grid item lg={12} sm={12} xs={12} sx={{zIndex: 8}}>
                    <Barra setOpen={setOpen}/>
                </Grid>

                <Grid
                    component={"div"}
                    id={"Inicio"}
                    item lg={12} sm={12} xs={12}>
                    <Inicio/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: sCell ? 12 : 8}}>
                    <Cifras/>
                </Grid>

                <Grid
                    component={"div"}
                    id={"Clientes"}
                    item lg={12} sm={12} xs={12} sx={{marginTop: sCell ? 12 : 8}}>
                    <Clientes/>
                </Grid>

                <Grid
                    component={"div"}
                    id={"Testimonios"}
                    item lg={12} sm={12} xs={12} sx={{marginTop: sCell ? 12 : 8}}>
                    <Testimonios/>
                </Grid>

                <Grid
                    component={"div"}
                    id={"Contactos"}
                    item lg={12} sm={12} xs={12} sx={{marginTop: sCell ? 12 : 8}}>
                    <Contactos/>
                </Grid>

                <Grid
                    component={"div"}
                    id={"Equipo"}
                    item lg={12} sm={12} xs={12} sx={{marginTop: sCell ? 12 : 14}}>
                    <Equipo/>
                </Grid>

                <Grid
                    component={"div"}
                    id={"Mas"}
                    item lg={12} sm={12} xs={12} sx={{marginTop: sCell ? 12 : 14}}>
                    <Servicios/>
                </Grid>


                <Grid
                    item lg={12} sm={12} xs={12} sx={{marginTop: sCell ? 12 : 14}}>
                    <Politicas/>
                </Grid>


                <Grid
                    component={"div"}
                    id={"Nosotros"}
                    item lg={12} sm={12} xs={12} sx={{marginTop: sCell ? 12 : 14}}>
                    <Nosotros/>
                </Grid>

                <Grid
                    component={"div"}
                    id={"pqrs"}
                    item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>

                    <Pqrs/>

                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: sCell ? 12 : 14}}>
                    <Footer/>
                </Grid>

            </Grid>


        </>
    )
}

export default Main;

const secciones = ["Inicio", "Clientes", "Testimonios", "Contactos", "Equipo", "Nosotros"]